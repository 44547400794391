@import 'src/routes/client/styles/index.scss';

.about-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: vw_d(74.8) 0 vw_d(186.5);
  @include screen('mobile') {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 vw(15);
  }
  .description {
    width: vw_d(608);
    display: flex;
    flex-direction: column;
    margin-left: vw_d(90);
    order: 2;
    @include screen('mobile') {
      width: 100%;
      order: 1;
    }
    p {
      font-family: $medium-font;
      font-size: vw_d(25);
      margin-bottom: vw_d(49.6);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      @include screen('mobile') {
        font-family: $regular-font;
        font-size: vw(18);
        font-weight: normal;
        line-height: 1.33;
        margin-bottom: vw(13.6);
      }
    }
  }
  .order {
    order: 1;
    @include screen('mobile') {
      order: 2;
    }
    .img-wrapper {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: vw_d(30.8);
      @include screen('mobile') {
        width: 100%;
        justify-content: center;
        margin: 0 0 vw(40.8) 0;
      }
      img {
        height: vw_d(626.2);
        width: vw_d(648.6);
        @include screen('mobile') {
          height: vw(294);
          width: vw(304);
          justify-content: center;
        }
      }
      > div {
        position: absolute;
        height: vw_d(530.2);
        width: vw_d(615.6);
        transform: rotate(13deg);
        left: vw_d(40);
        @include screen('mobile') {
          height: vw(300);
          width: vw(250);
          left: vw(35);
          top: vw(1);
        }
      }
    }
  }
}
