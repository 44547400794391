@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Marzipan Regular';
  src: url('/src/routes/client/assets/fonts/Marzipan-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Marzipan Bold';
  src: url('/src/routes/client/assets/fonts/Marzipan-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NarkissBlock Regular';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Regular.ttf');
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Regular.woff') format('woff'),
    url('/src/routes/client/assets/fonts/NarkissBlock-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock Bold';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Bold.ttf');
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Bold.woff') format('woff'),
    url('/src/routes/client/assets/fonts/NarkissBlock-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock Light';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Light.ttf');
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Light.woff') format('woff'),
    url('/src/routes/client/assets/fonts/NarkissBlock-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock Semibold';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Semibold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock Medium';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}
