@import 'src/routes/client/styles/index.scss';

.weather-wrapper {
  position: relative;
  width: vw_d(312);
  height: vw_d(31);
  border-radius: vw_d(16);
  @include screen('mobile') {
    width: vw(198);
    height: vw(26);
    margin-bottom: vw(52.9);
  }
  .weather-btn {
    height: 100%;
    width: 100%;
    font-family: 'NarkissBlock regular';
    font-weight: 400;
    font-size: vw_d(25);
    line-height: 1.31;
    text-align: right;
    color: #fff;
    padding: 0 vw_d(9.6) 0;
    border-radius: vw_d(16);
    background-color: #1317ac;
    display: block;
    @include screen('mobile') {
      font-size: vw(16);
      line-height: 1.31;
      text-align: center;
      color: #fff;
      border-radius: vw(16);
      background-color: #1317ac;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: vw(9.2);
    }
  }
  > div {
    position: absolute;
    height: vw_d(85);
    width: vw_d(95);
    top: vw_d(-31);
    left: 0;
    @include screen('mobile') {
      height: vw(50);
      width: vw(60);
      top: vw(-15);
    }
  }
}
