@import 'routes/client/styles/index.scss';

.tabs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: vw_d(31.8);
  @include screen('mobile') {
    padding: 0 vw(15);
    margin-bottom: vw(24.8);
  }
  .title {
    font-family: $semibold-font;
    font-size: vw_d(40);
    font-weight: 600;
    line-height: 1.28;
    text-align: center;
    color: #0064b3;
    margin-bottom: vw_d(38.8);
    @include screen('mobile') {
      font-family: $medium-font;
      font-size: vw(23);
      font-weight: normal;
      text-align: unset;
      margin-bottom: vw(24.8);
    }
  }
  .scrollable-wrapper {
    @include screen('mobile') {
      width: 100%;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .tabs {
      width: vw_d(1280);
      display: flex;
      justify-content: space-between;
      @include screen('mobile') {
        width: vw(750);
      }
      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: vw_d(15) vw_d(32);
        border-bottom: vw_d(4) solid transparent;
        cursor: pointer;
        @include screen('mobile') {
          width: vw(150);
          border-bottom: vw(3) solid transparent;
        }
        img {
          margin-bottom: vw_d(10);
        }
        .tab-title {
          font-family: $medium-font;
          font-size: vw_d(28);
          color: #b8b8b8;
          @include screen('mobile') {
            font-size: vw(18);
            margin-bottom: vw(17.5);
          }
        }
      }
      .active {
        border-bottom: vw_d(4) solid #0064b3;
        @include screen('mobile') {
          border-bottom: vw(3) solid #0064b3;
        }
        .tab-title {
          color: #0064b3;
        }
      }
    }
  }
}
