@import 'src/routes/client/styles/index.scss';

.tour-card-wrapper {
  width: vw_d(1280);
  height: vw_d(180);
  border-radius: vw_d(16);
  box-shadow: vw_d(0) vw_d(3) vw_d(5) vw_d(1) rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: vw_d(16);
  display: flex;
  align-items: flex-start;
  margin: vw_d(31.8) 0 vw_d(24);

  @include screen('mobile') {
    width: vw(345);
    height: unset;
    margin: vw(24) vw(15) vw(32.8);
    padding: vw(8);
    border-radius: vw(16);
    box-shadow: 0 vw(6) vw(16) 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    flex-direction: column;
  }

  .img-wrapper {
    position: relative;
    margin-left: vw_d(15);

    @include screen('mobile') {
      width: 100%;
      margin-left: 0;
    }

    img {
      width: vw_d(259);
      height: vw_d(149);
      border-radius: vw_d(10);
      @include screen('mobile') {
        height: vw(182.5);
        width: 100%;
        border-radius: vw(10);
      }
    }
    .play-btn {
      width: vw_d(46);
      height: vw_d(41);
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: vw_d(10);

      @include screen('mobile') {
        width: vw(42.7);
        height: vw(38);

        border-bottom-left-radius: vw(10);
      }

      svg {
        width: vw_d(46);
        height: vw_d(41);
        @include screen('mobile') {
          width: vw(42.7);
          height: vw(38);
          border-bottom-left-radius: vw(10);
        }
      }
    }
  }
  .col {
    height: 100%;
    width: vw_d(800);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include screen('mobile') {
      width: 100%;
      justify-content: unset;
    }

    .col-texts {
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        font-family: $medium-font;
        font-size: vw_d(24);
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: normal;
        color: #000;
        @include screen('mobile') {
          height: unset;
          font-size: vw(20);
          font-weight: normal;
          margin: vw(16.5) 0 vw(8.5);
        }
      }
      .description {
        margin-top: vw_d(8);
        width: 100%;
        font-family: $regular-font;
        font-size: vw_d(16);
        font-weight: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #000;
        @include screen('mobile') {
          height: unset;
          font-size: vw(18);
          margin-top: 0;
          margin-bottom: vw(16.5);
        }
      }
    }

    .categories-wrapper {
      margin-top: vw_d(8);
      width: 100%;
      height: vw_d(17);
      opacity: 0.55;
      display: flex;
      align-items: flex-start;
      @include screen('mobile') {
        height: unset;
        margin-top: 0;
        flex-wrap: wrap;
      }
      .category {
        font-family: $regular-font;
        font-size: vw_d(13);
        font-weight: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #0064b3;
        margin-left: vw_d(20);
        @include screen('mobile') {
          font-family: $light-font;
          font-size: vw(13);
          margin: 0 0 vw(3) vw(16);
        }
      }
    }
  }
  .btn-wrapper {
    height: 100%;
    width: vw_d(200);
    display: flex;
    justify-content: center;
    align-items: center;
    @include screen('mobile') {
      height: unset;
      width: 100%;
      margin-right: 0;
      margin-top: vw(16);
    }
    .tour-card-btn {
      width: vw_d(135);
      height: vw_d(48);
      border-radius: vw_d(5);
      background-color: #66b72d;
      text-align: center;
      font-family: $medium-font;
      font-size: vw_d(18);
      line-height: 1.33;
      letter-spacing: normal;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      @include screen('mobile') {
        height: vw(41);
        width: 100%;
        border-radius: vw(9);
        font-family: $bold-font;
        font-size: vw(16);
        font-weight: normal;
      }
    }
  }
}

.tip-wrapper {
  width: 100%;
  padding: vw_d(14.3) vw_d(378.4) vw_d(14.3) vw_d(253.6);
  object-fit: contain;
  box-shadow: 0 vw_d(3) vw_d(6) 0 rgba(135, 187, 229, 0.3);
  background-color: #fdecec;
  display: flex;
  @include screen('mobile') {
    width: vw(375);
    padding: vw(8.4) vw(20);
  }
  img {
    width: vw_d(59.5);
    height: vw_d(73.5);
    margin-left: vw_d(11.8);
    @include screen('mobile') {
      height: vw(38.5);
      width: vw(31.2);
    }
  }
  .col {
    display: flex;
    flex-direction: column;
    padding-top: vw_d(5.8);
    @include screen('mobile') {
      padding-top: 0;
    }
    .title {
      font-family: $semibold-font;
      font-size: vw_d(22);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: right;
      color: #e70003;
      @include screen('mobile') {
        font-family: $medium-font;
        font-size: vw(20);
        display: flex;
        align-items: center;
      }
      span {
        @include screen('mobile') {
          margin-top: vw(15);
        }
      }
    }
    .subtitle {
      font-family: $marzipan-regular-font;
      font-size: vw_d(31);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: right;
      color: #242424;
      margin-top: vw_d(-2);
      @include screen('mobile') {
        font-size: vw(18);
        margin-top: vw(8.7);
      }
    }
  }
}
