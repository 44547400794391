@import 'src/routes/client/styles/index.scss';

.cover {
  width: 100%;
  background-image: url('src/routes/client/assets/icons/header/cover.png');
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'NarkissBlock Bold';
  font-size: vw_d(34);
  font-weight: 900;
  @include screen('mobile') {
    height: unset;
    padding: vw(45) vw(22.2) 0;
    background-image: url('src/routes/client/assets/icons/header/mobile/cover-mobile.png');
    background-size: contain;
  }
  .header-wrapper {
    width: 100%;
    height: vw_d(86);
    padding: vw_d(29.6) vw_d(30.2);
    display: flex;
    justify-content: space-between;
    @include screen('mobile') {
      height: unset;
      padding: 0;
    }
    .icon-wrapper {
      display: flex;
      @include screen('mobile') {
        justify-content: center;
        align-items: center;
      }
      img {
        height: vw_d(30.4);
        margin-left: vw_d(20.6);
        cursor: pointer;
        @include screen('mobile') {
          height: vw(20.9);
          margin-left: vw(16.5);
        }
      }
    }
    .socials-wrapper {
      display: flex;
      .icon-wrapper {
        height: vw_d(30.4);
        width: vw_d(30.4);
        margin-right: vw_d(13);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: vw_d(13);
        @include screen('mobile') {
          width: vw(34.7);
          height: vw(34.7);
          margin-right: vw(13);
        }
        svg {
          height: vw_d(30.4);
          width: vw_d(30.4);
          cursor: pointer;
          @include screen('mobile') {
            width: vw(34.7);
            height: vw(34.7);
          }
        }
      }
    }
  }
  .inner-wrapper {
    width: 100%;
    height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: vw_d(200);
    @include screen('mobile') {
      flex-direction: column;
      margin-top: vw(109);
    }
    .tnuva-wrapper {
      width: vw_d(462);
      display: flex;
      flex-direction: column;
      margin: vw_d(40) 0 0 vw_d(105);
      justify-content: flex-start;
      @include screen('mobile') {
        width: unset;
        margin: 0 0 vw(100) 0;
      }
      svg {
        height: vw_d(199.4);
        width: vw_d(408.5);
        @include screen('mobile') {
          height: vw(116.6);
          width: vw(238.8);
        }
      }
      .title {
        width: vw_d(452);
        color: #2e58a6;
        font-size: vw_d(35);
        font-family: 'NarkissBlock Bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.03;
        letter-spacing: normal;
        margin-top: vw_d(42.9);
        @include screen('mobile') {
          width: vw(345);
          font-family: 'NarkissBlock Semibold';
          font-size: vw(20);
          line-height: 1.2;
          margin-top: vw(10.7);
          padding-right: vw(10);
          text-align: unset;
        }
      }
    }
    .col {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-left: vw_d(105);
      justify-content: flex-start;
      @include screen('mobile') {
        height: vw(245);
        margin-left: 0;
      }
      .mountains-animation-wrapper {
        height: vw_d(350);
        width: vw_d(713);
        @include screen('mobile') {
          width: vw(307.1);
          height: vw(245);
        }
      }
      svg {
        height: vw_d(199.4);
        width: vw_d(408.5);
        margin-bottom: vw_d(41.9);
        @include screen('mobile') {
          width: vw(185.1);
          height: vw(90.3);
        }
      }
      .vehicle-animation-wrapper {
        width: vw_d(713);
        position: absolute;
        top: vw_d(-110);
        @include screen('mobile') {
          height: vw(245);
          width: vw(307.1);
          top: vw(-47);
        }
      }
    }
  }
  .arrows-animation-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: vw_d(52.2);
    > div {
      height: vw_d(186);
      width: vw_d(186);
      margin-left: vw_d(100);
    }
  }
}

:global {
  body {
    &.acc-grayscale,
    &.acc-negative {
      overflow-x: hidden;
    }
  }
  #INDmenu-btn {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      // background-color: red;
      // border: 1px solid red;

      @include screen('mobile') {
        width: vw(23 * 2);
        height: vw(23 * 2);
        background-color: white;
      }
    }
  }

  #INDbtnWrap {
    #INDmenu-btn {
      position: fixed;
      top: vw_d(20) !important;
      right: initial;
      left: vw_d(20) !important;
      width: vw_d(40);
      height: vw_d(40);
      margin: 0;
      transition: none !important;
      opacity: 0 !important;
      visibility: hidden !important;

      @include screen('mobile') {
        position: absolute;
        top: vw(19) !important;
        left: vw(20) !important;
        width: vw(44);
        height: vw(44);
      }
    }
  }

  #INDmenu-btn svg {
    visibility: hidden;
  }
}
